/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, {
  useEffect, useRef, useState,
} from 'react';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import {
  Typography, Accordion, AccordionSummary,
  Table,
  TableHead,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  ChevronLeft, ChevronRight, Clear, SaveAlt, Search,
} from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import { useCompany } from '../../../hooks';

export const SiteBreakdown = React.memo(({
  orders,
  refunds,
}) => {
  const { sites: companySites } = useCompany();
  const { currency } = useCompany();
  const [expanded, setExpanded] = useState(false);
  const tableRef = useRef(null);
  const facilities = useSelector((state) => state.facilities.companyFacilities);
  const categories = useSelector((state) => state.addons.categories);
  let addons = useSelector((state) => (state.addons.addons));
  const archivedAddons = useSelector((state) => state.addons.archivedAddons);
  addons = addons.concat(archivedAddons);

  // Flatten out facilities and sub-facilities into a single array
  const flattenedFacilities = React.useMemo(() => {
    if (!facilities) {
      return [];
    }

    return facilities?.flatMap((x) => [
      { ...x, type: 'FACILITY' },
      ...(x.subFacilities.map((y) => ({ ...y, type: 'SUBFACILITY' })) ?? []),
    ]);
  }, [facilities]);

  // Organise orders into their respective sites
  const sites = React.useMemo(() => {
    const sites = {};

    // Map all refund ids into a set for easy lookup
    const refundedOrders = new Set([
      ...refunds.map((x) => x.refundedFromOrderId),
      ...refunds.flatMap((x) => x.checkoutOrders.map((y) => y.refundedFromOrderId)),
    ]);

    orders.forEach((x) => {
      if (refundedOrders.has(x.id)) {
        return;
      }

      const total = x.total / 100;
      let data = [];

      // We try manage the data structure, so it is consistent throughout
      if (x.reservations.length > 0 || x.subscriptions.length > 0) {
        data = [
          ...x.reservations.map((y) => ({
            id: y.id,
            type: 'reservation',
            category: y.type,
            total: x.partial ? total : y.total,
            siteId: y.siteId ?? y.allocations.find((z) => z.facility)?.facility?.site?.id,
            addonId: y.allocations.find((z) => z.addonId)?.addonId,
            facilities: [
              ...new Set(y.allocations.filter((z) => z.status !== 'ALTERED').filter((z) => z.facilityId).map((z) => z.facilityId)),
            ],
            categories: [
              ...new Set(y.allocations.filter((z) => z.status !== 'ALTERED').filter((z) => z.categoryId).map((z) => z.categoryId)),
            ],
          })),
          ...x.subscriptions.map((y) => ({
            id: y.id,
            type: 'subscription',
            category: 'RESERVATION',
            total: x.partial ? total : (y.amount / 100),
            siteId: y.accessRestriction?.facilities[0].site.id,
            facilities: [...new Set(y.accessRestriction?.facilities.map((z) => z.id))],
            categories: [],
          })),
        ];
      }

      for (const checkoutOrder of x.checkoutOrders) {
        if (refundedOrders.has(checkoutOrder.id)) {
          continue;
        }

        const reservations = checkoutOrder.reservations.filter((y) => y.type !== 'SERVICE_FEE');
        const subscriptions = checkoutOrder.subscription_orders;

        const checkoutOrderTotal = checkoutOrder.total / 100;

        data.push(...[
          ...reservations.map((y) => ({
            id: y.id,
            type: 'reservation',
            category: y.type,
            total: checkoutOrder.partial ? checkoutOrderTotal : y.total,
            siteId: y.siteId ?? y.allocations.find((z) => z.facility)?.facility?.site?.id,
            addonId: y.allocations.find((z) => z.addonId)?.addonId,
            facilities: [
              ...new Set(y.allocations.filter((z) => z.status !== 'ALTERED').filter((z) => z.facility).map((z) => z.facility.id)),
            ],
            categories: [
              ...new Set(y.allocations.filter((z) => z.status !== 'ALTERED').filter((z) => z.categoryId).map((z) => z.categoryId)),
            ],
          })),
          ...subscriptions.map((y) => ({
            id: y.id,
            type: 'subscription',
            category: 'RESERVATION',
            total: checkoutOrder.partial ? checkoutOrderTotal : (y.subscription.amount / 100),
            siteId: y.subscription.accessRestriction.facilities.siteId,
            facilities: [y.subscription.accessRestriction.facilities.id],
            categories: [],
          })),
        ]);
      }

      // Go through each order and put them into a site object
      for (const item of data) {
        const { siteId } = item;

        if (!siteId) {
          console.log('No Site ID', item);
          continue;
        }

        sites[siteId] = {
          orders: [...(sites[siteId]?.orders ?? []), item],
          total: (sites[siteId]?.total ?? 0) + item.total,
        };
      }
    });

    return sites;
  }, [orders, refunds]);

  // TODO: We don't need to push orders, we can just take the length of the orders
  // TODO: prevent too much data being pushed to the UI
  // TODO: Could maybe create a function to prevent code repetition below
  // Use selected site to breakdown orders into facilities and sub-facilities
  const { facilityBreakdown, sitePosOrders, total } = React.useMemo(() => {
    const facilityBreakdown = {};

    if (!sites) {
      return {
        facilityBreakdown,
        sitePosOrders: {},
        total: 0,
      };
    }

    const orders = Object.values(sites).flatMap((x) => x.orders);
    const nonPosOrders = orders.filter((x) => x.category !== 'POS');
    const posOrders = orders.filter((x) => x.category === 'POS');

    for (const order of nonPosOrders) {
      const orderFacilities = order.facilities;

      for (const facility of orderFacilities) {
        let fac = facilities.find((x) => x.id === facility);
        let subFacility = null;

        if (!fac) {
          subFacility = facility;
          fac = facilities.find((x) => x.subFacilities.some((y) => y.id === facility))?.id;
        } else {
          fac = fac.id;
        }
        const subfacilities = facilityBreakdown[fac]?.subfacilities ?? {};

        facilityBreakdown[fac] = {
          orders: [...(facilityBreakdown[fac]?.orders ?? []), order],
          total: (facilityBreakdown[fac]?.total ?? 0) + order.total,
          subfacilities: {
            ...subfacilities,
            ...(subFacility ? {
              [subFacility]: {
                orders: [
                  ...(facilityBreakdown[fac]?.subfacilities[subFacility]?.orders ?? []),
                  order,
                ],
                total: (
                  facilityBreakdown[fac]?.subfacilities[subFacility]?.total ?? 0
                ) + order.total,
              },
            } : {}),
          },
        };
      }
    }

    const posSiteFilteredOrders = {};
    for (const site of companySites) {
      posSiteFilteredOrders[site.id] = {
        orders: posOrders.filter((po) => po.siteId === site.id),
        total: posOrders.filter(
          (po) => po.siteId === site.id,
        ).reduce((acc, curr) => acc + curr.total, 0),
        totalOrders: posOrders.filter((po) => po.siteId === site.id).length,
        categoryBreakdown: posOrders.filter((po) => po.siteId === site.id).reduce((acc, curr) => {
          // add children to the parent addon
          const flattenedAddons = addons?.flatMap((addon) => {
            const childrenWithParentId = (addon.children || []).map((child) => ({
              ...child,
              parentId: addon.id,
            })) || [];
            return [addon, ...childrenWithParentId];
          });

          const retrievedAddon = flattenedAddons.find((x) => x.id === curr.addonId);

          let category;
          if (!retrievedAddon) {
            category = { name: 'Uncategorised' };
          } else {
            category = categories.find((x) => x.id === retrievedAddon?.categoryId);

            if (!category) {
              if (retrievedAddon?.parentId) {
                const parentAddon = flattenedAddons.find((x) => x.id === retrievedAddon.parentId);
                category = categories.find((x) => x.id === parentAddon?.categoryId) || { name: 'Uncategorised' };
              }
            }
          }

          const currentBreakdown = acc[category.name]?.breakdown ?? {};
          const retrievedAddonBreakdownCount = currentBreakdown[retrievedAddon.name] ?? {};
          const breakdown = {
            ...currentBreakdown,
            [retrievedAddon.name]: {
              orders: [...(retrievedAddonBreakdownCount?.orders ?? []), curr],
              total: (retrievedAddonBreakdownCount?.total ?? 0) + curr.total,
            },
          };

          if (!acc[category.name]) {
            acc[category.name] = {
              orders: [curr],
              total: curr.total,
              breakdown,
            };
          } else {
            acc[category.name].orders.push(curr);
            acc[category.name].total += curr.total;
            acc[category.name].breakdown = breakdown;
          }

          return acc;
        }, {}),
      };
    }

    return {
      facilityBreakdown,
      sitePosOrders: {
        orders: posOrders,
        total: posOrders.reduce((acc, curr) => acc + curr.total, 0),
        posSiteFilteredOrders,
      },
      total: Object.entries(sites).reduce((acc, curr) => acc + curr[1].total, 0),
    };
  }, [sites, facilities, flattenedFacilities, companySites, categories, addons]);

  // ! This is a hack to close the detail panel when the orders data changes
  useEffect(() => {
    if (tableRef.current && tableRef.current.dataManager) {
      const renderData = tableRef.current.dataManager.data;
      renderData.forEach((row) => {
        if (row.tableData && row.tableData.showDetailPanel) {
          row.tableData.showDetailPanel = false;
        }
      });
      tableRef.current.setState({ data: renderData });
    }
  }, [orders, tableRef]);

  return (
    <>
      <Accordion
        elevation={0}
        style={{
          backgroundColor: '#F5F5F5',
          border: '1px solid #ddd',
          borderRadius: '0 0 0.75rem 0.75rem',
          margin: '0 2rem 0 2rem',
        }}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography style={{ fontWeight: '600' }}>Site Breakdown</Typography>

        </AccordionSummary>

        <MaterialTable
          data={companySites.filter(
            (x) => sites[x.id]?.orders.length > 0,
          )}
          tableRef={tableRef}
          title="Pitchbooking Inventory Sales"
          icons={{
            NextPage: () => <ChevronRight />,
            PreviousPage: () => <ChevronLeft />,
            DetailPanel: () => <ChevronRight />,
            Search: () => <Search />,
            Export: () => <SaveAlt />,
            ResetSearch: () => <Clear />,
            SortArrow: () => <UnfoldMoreIcon style={{ fill: 'var(--accent)' }} />,
          }}
          style={{ zIndex: 0 }}
          columns={[
            {
              title: 'Site Name',
              field: 'siteName',
              render: (row) => (
                <p>{row.name}</p>
              ),
            },
            {
              title: 'Total Orders',
              render: (row) => (
                <p>
                  <NumberFormat displayType="text" thousandSeparator value={sites[row.id]?.orders?.length || 0} />
                </p>
              ),
            },
            {
              title: 'Total',
              render: (row) => (
                <p>
                  {getCurrencySymbol(currency)}
                  <NumberFormat displayType="text" thousandSeparator value={sites[row.id]?.total.toFixed(2) || '0.00'} />
                </p>
              ),
            },
            {
              title: '% of Total',
              render: (row) => (
                <p>
                  {sites[row.id]?.total ? `${((sites[row.id]?.total / total) * 100).toFixed(2)}%` : '0.00%'}
                </p>
              ),
            },
          ]}
          detailPanel={(row) => (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#f9f9f9',
                border: '1px solid #e0e0e0',
                borderRadius: '5px',
              }}
            >
              <Table>
                <TableHead>
                  <TableCell> Item</TableCell>
                  <TableCell>Quantity Sold</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>% of Parent</TableCell>
                  <TableCell>% of Total</TableCell>
                </TableHead>
                {Object.entries(facilityBreakdown).map((value) => {
                  const facility = flattenedFacilities.find((x) => x.id === value[0]);
                  if (facility.siteId !== row.id) return null;
                  return (
                    <React.Fragment key={`${row.id}-${facility.id}`}>
                      <TableRow>
                        <TableCell>
                          {facility?.name}
                        </TableCell>
                        <TableCell>
                          <NumberFormat displayType="text" thousandSeparator value={value[1].orders?.length || '0'} />
                        </TableCell>
                        <TableCell>
                          {getCurrencySymbol(currency)}
                          <NumberFormat displayType="text" thousandSeparator value={value[1].total?.toFixed(2) || '0.00'} />
                        </TableCell>
                        <TableCell>
                          {`${((value[1].total / sites[row.id].total) * 100).toFixed(2)}%`}
                        </TableCell>
                        <TableCell>
                          {`${((value[1].total / total) * 100).toFixed(2)}%`}
                        </TableCell>
                      </TableRow>
                      <>
                        {Object.entries(value[1].subfacilities).sort((x) => x[1].name)
                          .map((subFacility) => {
                            const subFac = flattenedFacilities.find(
                              (x) => x.id === subFacility[0],
                            );
                            return (
                              <TableRow key={`${row.id}-${subFac.id}`} style={{ paddingLeft: '1rem', backgroundColor: '#fffdfd' }}>
                                <TableCell>
                                  <div style={{ paddingLeft: '1rem' }}>
                                    -
                                    {' '}
                                    {subFac?.name}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div style={{ paddingLeft: '1rem' }}>
                                    <NumberFormat displayType="text" thousandSeparator value={subFacility[1].orders?.length || '0'} />
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div style={{ paddingLeft: '1rem' }}>
                                    {getCurrencySymbol(currency)}
                                    <NumberFormat displayType="text" thousandSeparator value={subFacility[1].total?.toFixed(2) || '0.00'} />
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div style={{ paddingLeft: '1rem' }}>
                                    { subFacility[1].orders?.length ? `${((subFacility[1].total / value[1].total) * 100).toFixed(2)}%` : '0.00%'}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div style={{ paddingLeft: '1rem' }}>
                                    { subFacility[1].orders?.length ? `${((subFacility[1].total / sites[row.id].total) * 100).toFixed(2)}%` : '0.00%'}
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </>
                    </React.Fragment>
                  );
                })}
                <TableRow>
                  <TableCell>
                    {sitePosOrders?.posSiteFilteredOrders[row.id] ? 'POS' : null}
                  </TableCell>
                  <TableCell>
                    <NumberFormat displayType="text" thousandSeparator value={sitePosOrders?.posSiteFilteredOrders[row.id]?.totalOrders || '0'} />
                  </TableCell>
                  <TableCell>
                    {getCurrencySymbol(currency)}
                    <NumberFormat displayType="text" thousandSeparator value={sitePosOrders?.posSiteFilteredOrders[row.id]?.total?.toFixed(2) || '0.00'} />
                  </TableCell>
                  <TableCell>
                    { sitePosOrders?.posSiteFilteredOrders[row.id]?.total ? `${((sitePosOrders?.posSiteFilteredOrders[row.id]?.total / sites[row.id]?.total) * 100).toFixed(2)}%` : '0.00%'}
                  </TableCell>
                  <TableCell>
                    { sitePosOrders?.posSiteFilteredOrders[row.id]?.total ? `${((sitePosOrders?.posSiteFilteredOrders[row.id]?.total / total) * 100).toFixed(2)}%` : '0.00%'}
                  </TableCell>
                </TableRow>
                {sitePosOrders?.posSiteFilteredOrders[row.id]
                 && Object.entries(sitePosOrders?.posSiteFilteredOrders[row.id]?.categoryBreakdown)
                   .map((category, idx) => (
                     <>
                       <TableRow style={{ paddingLeft: '1rem', backgroundColor: '#fffdfd' }} key={`${category[0]}-${idx}`}>
                         <TableCell>
                           <div style={{ paddingLeft: '1rem' }}>
                             -
                             {' '}
                             {category[0]}
                           </div>
                         </TableCell>
                         <TableCell>
                           <div style={{ paddingLeft: '1rem' }}>
                             <NumberFormat displayType="text" thousandSeparator value={category[1]?.orders?.length || '0'} />
                           </div>
                         </TableCell>
                         <TableCell>
                           <div style={{ paddingLeft: '1rem' }}>
                             {getCurrencySymbol(currency)}
                             <NumberFormat displayType="text" thousandSeparator value={category[1].total?.toFixed(2) || '0.00'} />
                           </div>
                         </TableCell>
                         <TableCell>
                           <div style={{ paddingLeft: '1rem' }}>
                             {category[1].total > 0
                           && sitePosOrders?.posSiteFilteredOrders[row.id]?.total ? (
                             <>
                               {`${((category[1].total / sitePosOrders?.posSiteFilteredOrders[row.id]?.total) * 100).toFixed(2)}%`}
                             </>
                               ) : '0.00%'}
                           </div>
                         </TableCell>
                         <TableCell>
                           <div style={{ paddingLeft: '1rem' }}>
                             {category[1].total > 0
                            && sitePosOrders?.posSiteFilteredOrders[row.id]?.total ? (
                              <>
                                {`${((category[1].total / total) * 100).toFixed(2)}%`}
                              </>
                               ) : '0.00%'}
                           </div>
                         </TableCell>
                       </TableRow>

                       {
                        category[1]?.breakdown
                        && Object.entries(category[1].breakdown).map((addon, idx) => (
                          <TableRow style={{ paddingLeft: '1rem', backgroundColor: '#fffdfd' }} key={`${category[0]}-${idx}-${addon[0]}`}>
                            <TableCell>
                              <div style={{ paddingLeft: '2rem' }}>
                                -
                                {' '}
                                {addon[0]}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div style={{ paddingLeft: '2rem' }}>
                                <NumberFormat displayType="text" thousandSeparator value={addon[1]?.orders?.length || '0'} />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div style={{ paddingLeft: '2rem' }}>
                                {getCurrencySymbol(currency)}
                                <NumberFormat displayType="text" thousandSeparator value={addon[1].total?.toFixed(2) || '0.00'} />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div style={{ paddingLeft: '2rem' }}>
                                {
                                  addon[1].total > 0
                                  && category[1]?.total ? (
                                    <>
                                      {`${((addon[1].total / category[1].total) * 100).toFixed(2)}%`}
                                    </>
                                    ) : '0.00%'
                                }
                              </div>
                            </TableCell>
                            <TableCell>
                              <div style={{ paddingLeft: '2rem' }}>
                                {
                                  addon[1].total > 0
                                  && category[1]?.total ? (
                                    <>
                                      {`${((addon[1].total / total) * 100).toFixed(2)}%`}
                                    </>
                                    ) : '0.00%'
                                }
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                     </>
                   ))}
              </Table>
            </div>
          )}
          options={{
            search: false,
            selection: false,
            emptyRowsWhenPaging: false,
            tableLayout: 'auto',
            exportButton: false,
            toolbar: false,
            exportFileName: 'Pitchbooking POS Orders',
            pageSize: 50,
            pageSizeOptions: [10, 25, 50, 100],
            showFirstLastPageButtons: false,
            showTitle: false,
          }}
          localization={{
            toolbar: {
              exportTitle: 'Download',
            },
          }}
        />
      </Accordion>
    </>
  );
});
